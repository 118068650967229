import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic_culinaire_1 from "../assets/images/pic_culinaire_1.jpg";
import pic_culinaire_2 from "../assets/images/pic_culinaire_2.jpg";
import Biographie from "../components/Biographie";

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe culinaire</title>
            <meta name="description" content="Photos culinaires Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Photos culinaires</h2>
                    </header>
                    <p>Une photo culinaire réussie, c'est une photographie qui donne envie de goûter.</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <span className="image "><img src={pic_culinaire_2} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <p>Photographier la gastronomie, c'est penser, imaginer, créer une image, un décor, une ambiance.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <span className="image "><img src={pic_culinaire_1} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <p>Que vous soyez amateur ou professionnel de la gastronomie, je me déplace sur votre lieu de travail ou à votre domicile pour réaliser un shooting gourmand.</p>
                        </div>
                    </div>
                </section>
            </section>
            <Biographie/>

        </div>

    </Layout>
)

export default Landing